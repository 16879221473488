<template>
  <div class="flex-container non-scrollable">
    <page-title value="Création de mission"/>
    <tableau-porte is-deletable :fields="fields" @reload="initMissions"/>
  </div>
</template>

<script>
import TableauPorte from '../../../components/TableauPorte'
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: 'CreateMission',
  components: {PageTitle, TableauPorte },
  data () {
    return {
      missions: [],
      fields: [
          'indepLastName',
          'indepFirstName',
          'clientContract',
          'clientFinal',
          'mission',
          'dateStart',
          'indepEmail',
          'indepPhone',
          'commercialFullName',
          'status',
      ]
    }
  },
  async created () {
    await this.initMissions()
  },
  methods: {
    async initMissions () {
      await this.$store.dispatch('mission/getAllMissionsNotCompleted')
    }
  }
}
</script>

<style scoped>

</style>
